import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import FlippyFront from './../../assets/images/flippy-15-front.png'
import FlippyBack from './../../assets/images/flippy-15-back-2.png'
import Express from './../../assets/images/express-15.png'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Ett kassasystem med stil.',
    paragraph: 'Vi ger er och era kunder den optimala upplevelsen med ett snabbt, lättanvänt och vackert gränssnitt.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={FlippyFront}
                      alt="Features tile icon 01"
                      width={500}
                      height={500} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Gör det enkelt för kökspersonalen
                    </h4>
                  <p className="m-0 text-sm">
                    Ett kassasystem behöver inte vara avancerat och svåranvänt - det motbevisar vi med vårt enkla men
                    funktionsrika användargränssnitt.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={FlippyBack}
                      alt="Features tile icon 02"
                      width={500}
                      height={500} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Förvandla kassan till en självbetjäningskärm
                    </h4>
                  <p className="m-0 text-sm">
                    Låt din personal fokusera på maten medan Rezto tar hand om kunderna.
                    </p>
                </div>
              </div>
            </div>



            {/* <div className="tiles-item reveal-from-bottom" data-reveal-delay="600">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={Express}
                      alt="Features tile icon 05"
                      width={400}
                      height={250} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Självbetjäningsskärmar i världsklass
                    </h4>
                  <p className="m-0 text-sm">
                    Öka effektiviten och servicen genom att placera ut så många självbetjäningskärmar som passar er
                    restaurang.
                    </p>
                </div>
              </div>
            </div> */}



          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
